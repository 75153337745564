var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Trans, useTranslation } from 'react-i18next';
import { CircularProgress, useTheme } from '@mui/material';
import styled from '@emotion/styled';
import { useMemo, } from 'react';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '@app/store/Hooks';
import { cancelEditStep, selectStopCopyingLimit, selectInvestAmount, submitStep, goToNextStep, } from '@entities/portfolio';
import { formatPercent, formatUSDT, numberToFixed2 } from '@shared/lib/numbers';
import { useGetExchangeAccountsQuery } from '@shared/api/exchange/api';
import { Body, BodySemiBold, BodyMedium, BodyMediumSemiBold, BodySmall, } from '@components/styled/Typography';
import QuantityInput from '@shared/ui/inputs/QuantityInput';
import SliderInput from '@shared/ui/inputs/SliderInput';
import { ContainerColumn, ContainerRow } from '@components/styled';
import { IsDefined } from '@utils/js-ts';
import { getWalletsFromExchanges } from '@entities/wallet';
import BidToQueueStep from './BidToQueueStep';
const Column = styled(ContainerColumn)(() => ({
    height: 'auto',
}));
const Form = styled.form();
const StopCopyingContainer = styled(ContainerRow)(props => ({
    height: 'auto',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: props.theme.spacing_sizes.m,
}));
const StopCopyingLabel = styled(ContainerRow)(props => ({
    height: 'auto',
    alignItems: 'center',
    gap: props.theme.spacing_sizes.s,
}));
const LoaderContainer = styled(ContainerColumn)(() => ({
    alignItems: 'center',
    justifyContent: 'center',
    height: 'auto',
    minHeight: 100,
}));
const TextRow = styled(ContainerRow)(() => ({
    height: 'auto',
    alignItems: 'center',
    gap: 7,
}));
const QuantityInputContainer = styled.div(() => ({
    minWidth: 186,
}));
const StopCopyingDetailedInfo = ({ investAmount, stopCopyingPercent, colorVariant = 'highlight', }) => {
    const theme = useTheme();
    const stopCopyPercentValue = useMemo(() => (investAmount * stopCopyingPercent) / 100, [investAmount, stopCopyingPercent]);
    const textColor = useMemo(() => {
        let color;
        switch (colorVariant) {
            case 'plain-white':
                color = theme.customColors.surface.surface;
                break;
            case 'highlight':
            default:
                color = theme.palette.text.secondary;
        }
        return color;
    }, [colorVariant, theme]);
    const highlightColor = useMemo(() => {
        let color;
        switch (colorVariant) {
            case 'plain-white':
                color = theme.customColors.surface.surface;
                break;
            case 'highlight':
            default:
                color = theme.palette.text.primary;
        }
        return color;
    }, [colorVariant, theme]);
    return (_jsx(Body, { color: textColor, children: Trans({
            ns: 'translation',
            i18nKey: 'portfolio.subscription.steps.enter_investment.stop_copying_details',
            values: {
                reaches: numberToFixed2(investAmount - stopCopyPercentValue),
                estimatedPNL: numberToFixed2(stopCopyPercentValue),
            },
            components: [(_jsx(BodySemiBold, { display: 'inline', 
                    // TODO: resolve.
                    // for some reason typescript does not see the 'component' prop
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    component: 'span', color: highlightColor }, 'bold'))],
        }) }));
};
const EnterInvestmentStepCollapsed = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { subscriptionData } = useAppSelector(state => state.portfolioSubscription);
    return (_jsxs(_Fragment, { children: [_jsxs(TextRow, { children: [_jsx(BodyMedium, { color: theme.palette.text.secondary, children: `${t('portfolio.subscription.steps.enter_investment.amount_label')}:` }), _jsx(BodyMediumSemiBold, { children: formatUSDT((subscriptionData === null || subscriptionData === void 0 ? void 0 : subscriptionData.investAmount) || 0, { maximumFractionDigits: 0 }) })] }), _jsxs(TextRow, { children: [_jsx(BodyMedium, { color: theme.palette.text.secondary, children: `${t('portfolio.subscription.steps.enter_investment.stop_copying_collapsed')}` }), _jsx(BodyMediumSemiBold, { children: formatPercent((subscriptionData === null || subscriptionData === void 0 ? void 0 : subscriptionData.stopCopyingLimit) || 0, { maximumFractionDigits: 0, disableSign: true }) })] })] }));
};
const BidToQueueEnterInvestmentStep = ({ stepName, nextStepName, }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const { portfolio, subscriptionData } = useAppSelector(state => state.portfolioSubscription);
    const { setValue, handleSubmit, watch } = useForm({
        defaultValues: subscriptionData,
    });
    const investAmount = watch('investAmount');
    const stopCopyingLimit = watch('stopCopyingLimit');
    const onBetAmountChange = (value) => {
        setValue('investAmount', value);
    };
    const onStopCopyingChange = (value) => {
        setValue('stopCopyingLimit', value);
    };
    const { data: wallets, isFetching, isError, } = useGetExchangeAccountsQuery(undefined, {
        selectFromResult: (_a) => {
            var { data } = _a, rest = __rest(_a, ["data"]);
            return (Object.assign(Object.assign({}, rest), { data: getWalletsFromExchanges(data || []) }));
        },
    });
    const maxInvestAmount = useMemo(() => {
        if ((subscriptionData === null || subscriptionData === void 0 ? void 0 : subscriptionData.walletId) && wallets.length) {
            const selectedWallet = wallets.find((wallet) => wallet.id === subscriptionData.walletId);
            if (selectedWallet) {
                return parseFloat(selectedWallet.totalAssetsUsdt);
            }
        }
        return 0;
    }, [subscriptionData, wallets]);
    const selectedWallet = useMemo(() => wallets === null || wallets === void 0 ? void 0 : wallets.find(e => e.id === (subscriptionData === null || subscriptionData === void 0 ? void 0 : subscriptionData.walletId)), [wallets, subscriptionData === null || subscriptionData === void 0 ? void 0 : subscriptionData.walletId]);
    const onSubmit = (values) => {
        dispatch(selectInvestAmount(values.investAmount));
        dispatch(selectStopCopyingLimit(values.stopCopyingLimit));
        dispatch(submitStep(stepName));
        dispatch(goToNextStep(nextStepName));
    };
    const onAction = (actionType) => {
        if (actionType === 'update') {
            dispatch(selectInvestAmount(investAmount));
            dispatch(selectStopCopyingLimit(stopCopyingLimit));
            dispatch(cancelEditStep(stepName));
        }
        else if (actionType === 'cancel' && IsDefined(subscriptionData)) {
            setValue('investAmount', subscriptionData.investAmount);
            setValue('stopCopyingLimit', subscriptionData.stopCopyingLimit);
        }
    };
    return (_jsxs(_Fragment, { children: [isFetching && (_jsx(LoaderContainer, { children: _jsx(CircularProgress, {}) })), !isFetching &&
                !isError &&
                IsDefined(portfolio) &&
                IsDefined(selectedWallet) && (_jsx(Form, { onSubmit: handleSubmit(onSubmit), children: _jsxs(BidToQueueStep, { stepName: stepName, title: t('portfolio.subscription.steps.enter_investment.label'), tooltipText: t('portfolio.subscription.steps.enter_investment.tooltip'), onAction: onAction, collapsedContent: (_jsx(EnterInvestmentStepCollapsed, {})), children: [_jsxs(Column, { children: [_jsx(BodyMedium, { children: t('portfolio.subscription.steps.enter_investment.amount_label') }), _jsx(Body, { color: theme.palette.text.secondary, children: t('portfolio.subscription.steps.enter_investment.available', { balance: maxInvestAmount }) })] }), _jsx(SliderInput, { value: investAmount, buttonAction: 'select_all', onChange: onBetAmountChange, 
                            // TODO-FIX-MOCKS: aggregate totalBalance from ExchangeWallets
                            minMaxValues: [portfolio.min_balance, maxInvestAmount] }), _jsxs(StopCopyingContainer, { children: [_jsxs(Column, { children: [_jsx(StopCopyingLabel, { children: _jsx(BodyMedium, { children: t('portfolio.subscription.steps.enter_investment.stop_copying') }) }), _jsx(BodySmall, { color: theme.palette.text.secondary, children: t('portfolio.subscription.steps.enter_investment.stop_copying_recommended', { percent: 15 }) })] }), _jsx(QuantityInputContainer, { children: _jsx(QuantityInput, { variant: 'percent', value: stopCopyingLimit, incDecStep: 1, min: 0, max: 100, onChange: onStopCopyingChange, fullWidth: true }) })] }), _jsx(StopCopyingDetailedInfo, { investAmount: investAmount, stopCopyingPercent: stopCopyingLimit })] }) }))] }));
};
export default BidToQueueEnterInvestmentStep;
